@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

body {
	background-color: #16213e;
}
